.about {    
    display:grid;
    grid-template-rows: 5% 90%;
    gap: 5%;
    height:70vh;
    /* background-color:yellow; */
    padding-top: 1rem;
    /* overflow: hidden; */
}

.about__container {
    display: grid;
    /* background-color:blue ; */
    grid-template-columns: 30% 65%;
    gap: 5%;
    height: 70vh;
    padding-top: 1rem;
}

.about__me {
    width: 100%;
    aspect-ratio:1/1;
    border-radius: 1rem;
    background: linear-gradient(
        45deg, 
        transparent,
        var(--color-primary),
        transparent
        );
    display: grid;
    place-items: center;
    }

.about__me-image {
        width: 100%;
        border-radius: 1rem;
        overflow: hidden;
        transform : rotate(5deg);
        transition: var(--transition);
    }

.about__me-image:hover {
        transform: rotate(0);
    }

    .about__cards {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 1.0rem;
    }

    .about__card {
        background-color: var(--color-bg-variant);
        border: 1px solid transparent;
        border-radius: 1rem;
        padding: 2rem;
        text-align: center;
        transition: var(--transition);

    }

    .about__card:hover {
        background: transparent;
        border-color: var(--color-primary-variant);
        cursor: default;

    }

    .about__icon {
        color: var(--color-primary);
        font-size: 1.4rem;
        margin-bottom:1rem;
    }
    .about_card h5 {
        font-size:0.95rem;
    }

    .about__card small {
        font-size:0.7rem;
        color:var(--color-light);
    }

    .about__content p{
        margin: 2rem 0 2.6rem;
        color: var(--color-text);

    }
/* === CTA ====*/

.cta_about {
    margin-top: 0rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}
    /* =============== media queries (tablets) ===============*/

@media screen and (max-width: 1024px) {
        /* header {
        height: 60vh;
    } */

    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me {
        width: 100%;
        margin: 2rem auto 4rem;
    }
    .about__content p{
        margin: 1rem 0 1.5rem;
    }
}
/* =============== media queries (phones) ===============*/

@media screen and (max-width: 600px) {
    /* header {
        height: 70vh;
    } */

    .about__me {
        width:65%;
        margin: 0 auto 3rem;
    }
    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap:1rem;
    }
    .about__content {
        text-align: left;
        height:50vh;
        /* background-color: red; */
    }
    .about__content p{
        margin: 1.5rem 0;
    }
}