

.behandelingen {    
    height: 50vh; 
    padding-top: 1rem;
}

.behandelingen__container {
    display:grid;
    text-align: left;
    /* background-color: green; */
    height: 50vh;
    /* position: relative; */
}

/* =============== media queries (tablets) ===============*/

@media screen and (max-width: 1024px) {
    /* header {
        height: 68vh;
    } */
    .behandelingen__container{
        height: 90vh;
        /* background-color: yellow; */
    }
}

/* =============== media queries (phones) ===============*/

@media screen and (max-width: 600px) {
    /* header {
        height: 68vh;
    } */
    .behandelingen__container{
        height: 50vh;
        /* background-color: yellow; */
        padding-top: 4rem;

    }

}