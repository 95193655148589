footer {
    /* clear:both;  */
    /* position:relative; */
    background: var(--color-primary);
    /* background: red; */
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    /* height:20rem; */
    /* margin-top: 7rem; */
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2.5rem;
    font-weight:500;
    margin-bottom: 2rem;
    display: inline-block;
}
.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}
.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;    
}

.footer__socials a:hover {
    background: transparent ;
    color: var(--color-bg);
    border-color: var(--color-bg);
}
.footer__copyright {
    margin-bottom: 6rem;
    color: var(--color-bg);
}


/* =============== media queries (phones) ===============*/

@media screen and (max-width: 600px) {
    
    .footer__logo {
        font-size: 2.5rem;
        font-weight:500;
        margin-bottom: 0.5rem;
        display: inline-block;
    }
    .permalinks {
        flex-direction: column;
        gap: 0.2rem;
        }
        .footer__socials {
            margin-bottom: 0 2.6rem;
        }
}
