header {    
    height: 50vh;
    /* background-color: red; */
    padding-top: 4rem;
    overflow: hidden;
}


.header__container {
    text-align: left;
    height: 100%;
    position: relative;
}


/* === CTA ====*/

.cta {
    margin-top: 0rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

/* === header Socials ====*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left:0;
    bottom:  3rem;
}

.header__socials::after {
    content:'';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* ====== Me ====*/

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 22rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 22rem 22rem 22rem 22rem;
    overflow: hidden;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

/* ===== img =======*/
.img_row {
    margin-top: 0.5rem;
    /* float:inline-start; */
    display: flex;
    object-fit:scale-down;
    width: 14rem;
    height:5rem;

  }
.img_col {
    flex: 50%; 

}

/* ====== scroll down ====*/

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 500;
    font-size: 0.9rem;

}

/* =============== media queries (tablets) ===============*/

@media screen and (max-width: 1024px) {
    /* header {
        height: 60vh;
    } */
    .header__container{
        height: 30vh;
        /* background-color: green; */

    }
}

/* =============== media queries (phones) ===============*/

@media screen and (max-width: 600px) {
    /* header {
        height: 10vh;
        background-color: green;
    } */
    .header__container{
        height: 50vh;
        /* background-color: green; */

    }
    .header__socials, 
    .scroll__down {
        display: none;
    }
}