.tarieven {    
    display: flex;
    flex-direction: column;
    height: 40vh; 
    padding-top: 0rem;
}

.tarieven__container {
    display: flex;
    flex-direction: column;

    text-align: left;
    /* background-color: green; */
    height: 40vh;
    /* position: relative; */
}


/* =============== media queries (tablets) ===============*/

@media screen and (max-width: 1024px) {
    .tarieven__container {
        display:flex;
        text-align: left;
        height: 40vh;
    }
    
}

/* =============== media queries (phones) ===============*/

@media screen and (max-width: 600px) {
    .tarieven {    
        display:flex;
        flex-direction: row;
        height: 120vh; 
        padding-top: 0rem;
    }
    .tarieven__container {
        display: flex;
        text-align: left;

        height: 160vh;
        /* background-color: blue; */
    }
    
    
}
