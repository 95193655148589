
.container.contact__container {
    width: 60%;
    min-height: 40vh;
    display: grid;
    grid-template-columns: 35% 60%;
    gap: 5%;
    padding: 0rem 1rem;

}

.contact__options {
    display: flex;
    flex-direction: column;
    gap:0.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    color: var(--color-white);

    padding: 0.5rem;
    border-radius: 2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);

}

.contact__option:hover {
    background: lightgrey;
    color: var(--color-text);

    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    color: white;

   font-size: 1.5rem;
margin-bottom: 0.5rem;
}

.contact__option-icon-wa {
    font-size: 1.5rem;
 margin-bottom: 0.5rem;
 }
 .contact__option-icon-wa:hover {
    color: #25D366;
    font-size: 1.5rem;
 margin-bottom: 0.5rem;
 }

 .contact__option-icon:hover {
    color: black;
    font-size: 1.5rem;
 margin-bottom: 0.5rem;
 }

 .contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
 }

 /* ==================== FORM =============*/ 

 form {

    /* background-color: red; */
    display:inline-block;
    /* flex-direction: column; */
    gap:0.2rem;

 }

 input, textarea {
    width:100%;
    padding:0.5rem;
    border-radius: 0.2rem;
    border:1.5px solid var(--color-primary-variant);
    resize: none;
    /* color: var(--color-white); */
 }
 

  /* =============== media queries (tablets) ===============*/

@media screen and (max-width: 1024px) {
    .container.contact__container{
        grid-template-columns: auto;
        gap: 1rem;
        /* background-color: #25D366; */
        /* height: 200vh; */
    }
}
/* =============== media queries (phones) ===============*/

@media screen and (max-width: 600px) {
    
    
    .container.contact__container{
        grid-template-columns: auto;

        width: var(--container-width-sm);
        gap:1rem;
        
    }
}